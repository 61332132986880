<template>
  <v-container>
    <loader v-if="isLoading" message="Loading ...." />
    <div class="pt-6 pa-0" v-else>
      <s-tabs class="mb-8">
        <v-tab :to="{ name: 'appAnalytics' }"> Analytics </v-tab>
        <v-tab :to="{ name: 'appDetails' }"> Details </v-tab>
        <v-tab :to="{ name: 'appLogs' }"> Logs </v-tab>
        <div class="ml-auto">
          <v-select
            style="width: 220px"
            filled
            label="Environment"
            :items="app.environments"
            item-text="name"
            item-value="name"
            @change="handleEnvironmentChange"
          ></v-select>
        </div>
      </s-tabs>
      <router-view :id="id" />
    </div>
  </v-container>
</template>
<script>
import Loader from '@/components/cards/Loader'
import { SimpleEventBus } from '@/services/core/EventBus'
import { mapGetters } from 'vuex'
export default {
  name: 'AppDetails',
  components: {
    loader: Loader,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isDeleting: false,
      isLoading: false,
      dialog: false,
      showDeleteDialog: false,
      showModal: this.$route.meta.showModal,
    }
  },
  computed: {
    ...mapGetters({
      app: 'app/currentApp',
      selectedEnv: 'app/currentAppEnv',
    }),
    appEndpoints() {
      return this.$store.state.app.newApp.endpoints
    },
    routeName() {
      return this.$route.name
    },
  },
  methods: {
    async getAppDetails() {
      this.isLoading = true
      await this.$store.dispatch('app/getAppDetails', this.$route.params.id)
      this.isLoading = false
    },
    handleEnvironmentChange(selectedItem) {
      // Do something with the selected item
      this.$store.dispatch('app/getAppEnv', selectedItem)
    },
  },
  watch: {
    app: {
      handler(app) {
        SimpleEventBus.$emit('updateHeader', this.app.name)
      },
      immediate: true,
    },
  },
  async created() {
    await this.getAppDetails()
  },
  destroyed() {
    this.$store.dispatch('app/clearCurrentApp')
    this.$store.dispatch('app/clearCurrentAppLogs')
    SimpleEventBus.$emit('updateHeader', null)
  },
}
</script>
<style lang="scss" scoped></style>
